import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { SKIP_RENDER } from '../constants/semanticConstants';
import {
    componentName as contentNavigationComponentName,
    CONTENT_NAVIGATION_HEIGHT,
} from '@mediashop/catalog-base/pattern/molecule/ContentNavigation/ContentNavigation';
import { componentName as accordionItemComponentName } from '@mediashop/catalog-base/pattern/molecule/AccordionPDP/AccordionItem/AccordionItem';
import { removeLocaleFromPathname } from '../helper/localeHelper';
import { componentName as headerLogoComponentName } from '@mediashop/catalog-base/pattern/organism/MainNavigation/HeaderLogo';

const smoothScrollTo = (position: number, duration = 500) => {
    const userAgent = window.navigator.userAgent;
    const safari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);

    if (safari) {
        const startPosition = window.scrollY;
        const distance = position - startPosition;
        let startTime: number | null = null;

        const ease = (elapsedTime: number, startValue: number, distance: number, duration: number) => {
            elapsedTime /= duration / 2;
            if (elapsedTime < 1) {
                return (distance / 2) * elapsedTime * elapsedTime + startValue;
            }
            elapsedTime--;
            return (-distance / 2) * (elapsedTime * (elapsedTime - 2) - 1) + startValue;
        };

        const animation = (currentTime: number) => {
            if (startTime === null) {
                startTime = currentTime;
            }
            const timeElapsed = currentTime - startTime;
            const nextScrollY = ease(timeElapsed, startPosition, distance, duration);

            window.scrollTo(0, nextScrollY);
            if (timeElapsed < duration) {
                requestAnimationFrame(animation);
            }
        };

        requestAnimationFrame(animation);
    } else {
        setTimeout(
            () =>
                window.scrollTo({
                    top: position,
                    behavior: 'smooth',
                }),
            100
        );
    }
};

const addQueryParamToPath = (pathname: string, search: string): string => {
    const searchParams = new URLSearchParams(search);
    const queryParam = searchParams.get('q');
    return queryParam ? `${pathname}?p=${queryParam}` : pathname;
};

export const pathMap = new Map<string, number>();
const lastPathnames: string[] = [];

const ScrollManager = (): null => {
    const { pathname, search, hash } = useLocation();
    const pathnameWithoutLocale = addQueryParamToPath(removeLocaleFromPathname(pathname), search);

    const shouldRestoreScroll = (pathname: string): boolean => {
        return (
            pathname.includes('/c/') ||
            pathname.includes('search-results') ||
            (pathname === '/' &&
                pathname === lastPathnames[0] &&
                lastPathnames.some((lastPathname) => lastPathname.includes('/p/')))
        );
    };

    const scrollToTop = (behavior: ScrollBehavior = 'smooth'): void => {
        if (behavior === 'instant') {
            window.scrollTo({
                top: 0,
                behavior,
            });
        } else {
            smoothScrollTo(0);
        }
    };

    const restoreLastScrollPosition = () => {
        setTimeout(() => window.scrollTo(0, pathMap.get(pathnameWithoutLocale)!), 300);
    };

    const scrollToAnchor = (hash: string) => {
        setTimeout(() => {
            let anchor = document.querySelector(hash);
            const contentNavigation = document.querySelector(`.${contentNavigationComponentName}`);

            if (!anchor) {
                anchor = document.querySelector(`[name="${hash.slice(1)}"]`);
            }

            if (anchor) {
                anchor.scrollIntoView({ block: 'start', behavior: 'instant' });

                // Expand Accordion on PDP
                const clickableAnchor = anchor.getElementsByClassName(`${accordionItemComponentName}__heading`);
                const anchorAccordionItem = anchor.getElementsByClassName(`${accordionItemComponentName}__item`);
                if (clickableAnchor.length && !anchorAccordionItem.length) {
                    (clickableAnchor[0] as HTMLElement).click();
                }
            }

            if (contentNavigation) {
                window.scrollBy(0, -CONTENT_NAVIGATION_HEIGHT);
            }
        }, 700);
    };

    const scroll = () => {
        if (shouldRestoreScroll(pathnameWithoutLocale)) {
            if (pathMap.has(pathnameWithoutLocale)) {
                restoreLastScrollPosition();
            } else {
                pathMap.set(pathnameWithoutLocale, 0);
                scrollToTop();
            }
        } else if (hash.length) {
            scrollToAnchor(hash);
        } else {
            const scrollBehaviour = pathnameWithoutLocale.includes('/p/') ? 'instant' : 'smooth';
            scrollToTop(scrollBehaviour);
        }

        lastPathnames.push(pathnameWithoutLocale);
        if (lastPathnames.length > 2) {
            lastPathnames.shift();
        }
    };

    const handleNativeLinkClick = (event: MouseEvent) => {
        const target = event.target as HTMLAnchorElement;

        const headerLogoClicked = target.closest('div')?.parentElement?.classList.contains(headerLogoComponentName);
        if (headerLogoClicked) {
            setTimeout(() => scrollToTop('instant'), 200);
            return;
        }

        if (target.tagName === 'A' && target.href.includes(pathnameWithoutLocale) && !target.href.includes('#')) {
            scrollToTop();
        } else if (target.tagName === 'A' && target.href.includes(pathnameWithoutLocale) && target.href.includes('#')) {
            const href = target.href.split('#')[1];
            scrollToAnchor(`#${href}`);
        }
    };

    const addToPathmap = debounce(() => {
        if (
            pathnameWithoutLocale.includes('/c/') ||
            pathnameWithoutLocale.includes('search-results') ||
            pathnameWithoutLocale === '/'
        ) {
            pathMap.set(pathnameWithoutLocale, window.scrollY);
        }
    }, 200);

    useEffect(() => {
        scroll();

        window.addEventListener('scroll', addToPathmap);
        window.addEventListener('click', handleNativeLinkClick);
        return () => {
            window.removeEventListener('scroll', addToPathmap);
            window.removeEventListener('click', handleNativeLinkClick);
        };
    }, [pathnameWithoutLocale]);

    useEffect(() => {
        if (hash) {
            scrollToAnchor(hash);
        }
    }, [hash]);

    return SKIP_RENDER;
};

export default ScrollManager;
