import { Product, VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { FormattedMessage } from 'react-intl';
import Slider from '@mediashop/base/pattern/molecule/Slider';
import { SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';

const componentName = 'product-details-thumbnails-gallery';

type Props = {
    hasProductVideo: boolean;
    pictures?: VariantAsset[];
    setThumbSlider: (slider?: SwiperCore) => void;
    showProductVideo: () => void;
    product: Product;
};

export function ProductDetailsThumbnailsGallery({
    hasProductVideo,
    pictures = [],
    setThumbSlider,
    showProductVideo,
    product,
}: Props): JSX.Element | null {
    const slidesToShow = pictures && pictures.length <= 4 ? pictures.length : 4;
    const deviceType = useDeviceType();

    if (deviceType === 'mobile' || deviceType === 'tablet') {
        return null;
    }

    return (
        <div className={`${componentName}--wrapper`}>
            {/* Product Video */}
            {hasProductVideo ? (
                <div className={`${componentName}--video-wrapper`} onClick={showProductVideo}>
                    <Icon name="PlayButton" className={`${componentName}__playbutton`} />
                    <span className={`${componentName}__videotext`}>
                        <FormattedMessage id="product.videoThumbnailText" />
                    </span>
                </div>
            ) : (
                SKIP_RENDER
            )}

            {/* Thumbnail Gallery */}
            <Slider
                className={componentName}
                direction="vertical"
                loop={pictures?.length >= slidesToShow * 2}
                watchSlidesProgress
                slidesPerView={slidesToShow}
                onSwiper={setThumbSlider}
                spaceBetween={6}
                showPagination={false}
            >
                {pictures?.map((image, key) => (
                    <div
                        key={`thumbslider-${key}`}
                        className={`${componentName}__thumbnail-container background--default`}
                    >
                        {image?.sources ? (
                            <SwiperSlide key={`thumbslider-${key}-item`}>
                                <CroppedImage
                                    altText={product.name}
                                    className={`${componentName}__image`}
                                    src={image.sources[0]?.uri}
                                    variant={CroppedImageVariant.PDPThumbnail}
                                    fetchPriority="low"
                                />
                            </SwiperSlide>
                        ) : (
                            SKIP_RENDER
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
}
